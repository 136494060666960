<template>
  <div class="page">
    <div class="username">
      <van-popover
        v-model="showPopover"
        trigger="click"
        placement="bottom"
        :actions="actions"
        @select="onSelect"
      >
        <template #reference>
          <van-button type="primary">{{ cusername }}</van-button>
        </template>
      </van-popover>
    </div>
    <div class="card">
      <div class="text">
        <h1 v-show="showText" >查询车辆信息</h1>
        <van-loading v-show="showLoding" size="72px" text-color="#0094ff" vertical>加载中...</van-loading>
      </div>
      <div class="search">
        <van-search
        v-model.trim="value"
        autofocus
        placeholder="最少连续4位车牌号"
        @search="onSearch"
        @cancel="onCancel"
      />
      <van-button type="info" @click="onBtn">查询车辆</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  name: "carsearch",
  data() {
    return {
      value: "",
      showPopover: false,
      // 通过 actions 属性来定义菜单选项
      actions: [{ text: "退出" }],
      carInfo: {},
      cardInfo:{},
      detailInfo: {},
      token: "",
      username: "",
      showLoding:false,
      showText:true
    };
  },
  methods: {
    async onSearch(val) {
      if(val.length<4){
        Toast('最少输入4位连续号码')
        return
      }
      this.showText = false
      this.showLoding = true
      try {
      this.$store.state.detailInfo = {};
      let res = await this.$api.Search({ plate: val, page: 1 }, this.ctoken);
      if (
        !res.data.code ||
        res.data.code == "999999" ||
        res.data.status == "0000"
      ) {
        Toast(res.data.msg);
        setTimeout(() => {
          this.$emit("logOut");
        }, 500);
        return;
      }
      if (!res.data.data.totalNum) {
        Toast("查无此车，请重新输入!");
        this.showText = true
        this.showLoding = false
        this.value = "";
        return;
      }
      if (res.data.code == "200") {
        this.carInfo.orderId = res.data.data.datas[0].orderId;
        this.carInfo.plate = res.data.data.datas[0].plate;
        this.carInfo.parkId = res.data.data.datas[0].parkId;

        this.$store.state.orderId = this.carInfo.orderId;
        this.$store.state.parkId = this.carInfo.parkId;
         // 二次查询获取车辆详细信息
        //  console.log(this.$store.state.parkId)
         await this.secSearch()
      }else{
        return
      }
      this.value = "";
    } catch (error) {
          Toast(error);
        }},
//查询按钮
        async onBtn() {
      if(this.value.length<4){
        Toast('最少输入4位连续号码')
        return
      }
      this.showText = false
      this.showLoding = true
      try {
      this.$store.state.detailInfo = {};
      let res = await this.$api.Search({ plate: this.value, page: 1 }, this.ctoken);
      if (
        !res.data.code ||
        res.data.code == "999999" ||
        res.data.status == "0000"
      ) {
        Toast(res.data.msg);
        setTimeout(() => {
          this.$emit("logOut");
        }, 500);
        return;
      }
      if (!res.data.data.totalNum) {
        Toast("查无此车，请重新输入!");
        this.showText = true
        this.showLoding = false
        this.value = "";
        return;
      }
      if (res.data.code == "200") {
        this.carInfo.orderId = res.data.data.datas[0].orderId;
        this.carInfo.plate = res.data.data.datas[0].plate;
        this.carInfo.parkId = res.data.data.datas[0].parkId;

        this.$store.state.orderId = this.carInfo.orderId;
        this.$store.state.parkId = this.carInfo.parkId;
         // 二次查询获取车辆详细信息
         console.log(this.$store.state.parkId)
         await this.secSearch()
      }else{
        return
      }
      this.value = "";
    } catch (error) {
          Toast(error);
          return
        }},



    // 二次查询获取车辆详细信息
    secSearch(){
            let res1 =  this.$api.Detail(
            {
              orderId: this.$store.state.orderId,
              parkId: this.$store.state.parkId,
            },
            this.ctoken
          );
          res1.then(res=>{
            if (res.data.code == "200") {
            // console.log("详细",res1.data.data)
            this.detailInfo.duration = res.data.data.duration;
            this.detailInfo.discountAmount = res.data.data.discountAmount;
            this.detailInfo.enterTime = res.data.data.enterTime;
            this.detailInfo.image = res.data.data.image;
            this.detailInfo.lastDiscount = res.data.data.lastDiscount;
            this.detailInfo.lastEndTime = res.data.data.lastEndTime;
            this.detailInfo.needAmount = res.data.data.needAmount;
            this.detailInfo.orderId = res.data.data.orderId;
            this.detailInfo.parkName = res.data.data.parkName;
            this.detailInfo.photoEnabled = res.data.data.photoEnabled;
            this.detailInfo.pkCouponRecordId = res.data.data.pkCouponRecordId;
            this.detailInfo.plate = res.data.data.plate;
            this.detailInfo.timeCouponId = res.data.data.timeCouponId;
            this.detailInfo.unpaidAmount = res.data.data.unpaidAmount;
            this.detailInfo.versionFlag = res.data.data.versionFlag;
            this.detailInfo.coupons =
              res.data.data.coupons.length > 0
                ? res.data.data.coupons
                : 0;
            // console.log("详细信息",this.detailInfo)
            this.$store.state.detailInfo = this.detailInfo;
            // console.log(this.detailInfo.plate)
            this.getCard()
            this.$emit("goDtail");
          }else{
            return
          }
          })
          
          },
    // // 查询车票
    async getCard(){
      // this.$store.state.cardInfo = {}
      let res = await this.$api.getCard(this.ctoken)
      if(res.data.code=="200"){
        this.cardInfo.couponId = res.data.data[0].couponId
        this.cardInfo.todayRemainderNum = res.data.data[0].todayRemainderNum
        this.cardInfo.discountValue = res.data.data[0].discountValue
        this.$store.state.cardInfo = this.cardInfo
        // console.log(this.$store.state.cardInfo)
        this.showLoding = false
      }else{
        return
      }
      
    },
    onCancel() {
      Toast("取消");
    },
    onSelect() {
      this.$emit("logOut");
    },
  },
  created() {
    if (!this.$cookies.isKey("token")) {
      this.$emit("toLogin");
    } else {
      this.$store.state.token = this.$cookies.get("token");
      this.$store.state.username = this.$cookies.get("userName");
      this.token = this.$store.state.token;
      this.username = this.$store.state.username;
    }
  },
  computed: {
    cusername() {
      return !this.$store.state.username
        ? this.$cookies.get("userName")
        : this.$store.state.username;
    },
    ctoken() {
      return !this.$store.state.token
        ? this.$cookies.get("token")
        : this.$store.state.token;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: url("../../assets/search.gif");
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-size: 100% 100%;
  text-align: center;
  .username {
    position: absolute;
    right: 0.5vw;

/deep/ .van-popover__wrapper {
      .van-button {
        height: 5vh;
        margin: 1vw;
        border-radius: 10%;
        font-weight: 400;
      }
      .van-button--primary {
        background: #c0a2c7;
        border: #f2a3ab;
      }
    }
  }
  .card {
    width: 70vw;
    height: 5vh;
    margin: 25vh auto;
    // padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text {
      color: #fc617d;
    }
    .search{
      padding-top: 50px;
      height: 50px;
      line-height: 50px;
      display: flex;
      justify-content: center;
      align-items:end;
    }
  }
}
.van-button{
  margin-left: 10px;
  height: 57px;
  border-radius: 15%;
  font-weight: 700;
}
/deep/ .van-search {
  // margin-top: 10px;
  border-radius: 25px;
  .van-search__content,
  .van-search__content--square {
    background-color: #fff;
  }
  .van-cell {
    font-size: 18px;
  }
  .van-icon-search::before {
    font-size: 24px;
  }
}
</style>