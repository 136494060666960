import Vue from 'vue'
import App from './App.vue'
import store from './store'
import cookies from 'vue-cookies'
// import 'amfe-flexible'
Vue.use(cookies)
// 导入vant.js文件
import '@/plugins/vant'
// 导入css
import '@/assets/css/base.css'
// 导入api
import api from './api'
// 挂载到vue原型上
Vue.prototype.$api = api
Vue.config.productionTip = false

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
