<template>
  <div id="app">
    <home></home>
  </div>
</template>
<script>
import Home from '@/views/Home.vue'
export default{
  name:'App',
  components:{
    Home
  }
}
window.onload = function(){
  document.addEventListener('touchstart',function(event){
    if(event.touches.length>1){
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart',function(event){
    event.preventDefault()
  })
  var lastTouchEnd = 0
  document.documentElement.addEventListener('touchend',function(event){
    var now = Date.now()
    if(now-lastTouchEnd <=300){
      event.preventDefault()
    }
    lastTouchEnd = now
  },{
    passive:false
  })
}
</script>

<style lang="less">
#app{
  background:url('./assets/carlogin1.gif');
  // background: #84b9cb;
  // 此处给固定定位，达到页面就不滑动效果
  width:100%;
  height: 100vh;
  position: fixed;
  background-size: 100% 100%;
  max-width: 100%;
  overflow-x: hidden;
}
</style>
