<template>
  <div class="home">
    <!-- 公共的顶部logo -->
    <div @mousemove="changImg" @mouseleave="changImg1" class="header" :style="randomGradientStyle">
      <img src="@/assets/hg.png" />
      <!-- <img v-show="showQrcode" src="@/assets/qrcode.jpg" /> -->
      <div class="time">
        <!-- <span>{{nyr}}</span> -->
        <div>{{ nowtime | updatetime }}</div>
        <div>{{ nowtime | updatetime1 }}</div>

      </div>
    </div>
      <!-- <van-notice-bar
  left-icon="volume-o"
  color="#1989fa" 
  
  mode="closeable"
  text="欢迎使用柏适酒店停车管理系统 @Author:MarLin"
/> -->
        <car-login v-if="isnLogin" @goSearch="goSearch"></car-login>
        <car-search
          v-if="isSearch"
          @goDtail="goDtail"
          @logOut="logOut"
          @toLogin="toLogin"
        ></car-search>
        <car-detail
          v-if="isDetail"
          @goSearch="goSearch"
          @toLogin="toLogin"
        ></car-detail>
    <!-- <div class="gif" v-if="islogo"></div> -->
    <div class="foot">
      <img v-show="showQrcode" src="@/assets/qrcode.jpg" />
      <img v-show="showNull" src="@/assets/null.png" />

      ©2024 MarLin | Ver:1.0</div>
  </div>
</template>

<script>
import { Toast } from "vant";
import CarSearch from "./car/CarSearch.vue";
import CarLogin from "./car/CarLogin.vue";
import CarDetail from "./car/CarDetail.vue";

import moment from "moment";

moment.locale("zh-cn", {
  weekdays: "星期日_星期一_星期二_星期三_星期四_星期五_星期六".split("_"),
});
export default {
  components: {
    CarSearch,
    CarLogin,
    CarDetail,
  },
  data() {
    return {
      islogo: true,
      isnLogin: true,
      isSearch: false,
      isDetail: false,
      info: {
        token: "",
        username: "",
      },
      // 房间登录信息
      rname: "",
      rpwd: "",
      gcId: "",
      hstyle: {
        color1: '#'+ Math.random().toString(16).substring(2,8),
        color2: '#'+ Math.random().toString(16).substring(2,8),
        color3: '#'+ Math.random().toString(16).substring(2,8),
        color4: '#'+ Math.random().toString(16).substring(2,8),

        // a: Math.random(),
      },
      nowtime: new Date(),
      AlltodoList: [],
      showQrcode:false,
      showNull:true
    };
  },
  filters: {
    updatetime: (value) => {
      return moment(value).format("YYYY年 MM月 DD日  dddd");
    },
    updatetime1: (value) => {
      return moment(value).format("HH:mm:ss");
    },
  },
  mounted() {
    let _this = this;
    this.timer = setInterval(() => {
      _this.nowtime = new Date();
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  created() {
    if (this.$cookies.get("token")) {
      this.$store.state.token = this.$cookies.get("token");
      this.$store.state.username = this.$cookies.get("userName");
      this.goSearch();
    } else {
      this.toLogin();
    }
  },
  methods: {
    goDtail() {
      // this.detailInfo = val
      this.isnLogin = false;
      this.isSearch = false;
      this.isDetail = true;
    },
    goSearch() {
      this.isnLogin = false;
      this.isSearch = true;
      this.isDetail = false;
    },
    logOut() {
      this.$store.state.token = "";
      this.$cookies.remove("token");
      this.isnLogin = true;
      this.isSearch = false;
      this.isDetail = false;
    },
    toLogin() {
      this.isnLogin = true;
      this.isSearch = false;
      this.isDetail = false;
    },
    changImg(){
      this.showNull = false
      this.showQrcode = true
    },
    changImg1(){
      this.showNull = true
      this.showQrcode = false
    }
  },
  computed: {
    randomGradientStyle() {
      return {
        background: `linear-gradient(to right,${this.hstyle.color1},${this.hstyle.color2})`,
      };
    },
  },
};
</script>

<style lang='less' scoped>
.header {
  // border-bottom: 3px solid #c6ffdd;
  display: flex;
  justify-content: space-between;
  height: 11vh;
  line-height: 11vh;
  img {
    height: 10vh;
    padding: 1vh;
    // margin-top: 0.1rem;
  }

  .time {
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    text-align: center;
    width: 80vw;
    // padding-right: 5px;
    margin-right: 0vw;
    // margin-top: 20px;
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    div {
      margin-left: -20vw;
      height: 4vh;
      line-height: 8vh;
    }
  }
}
/deep/ .van-tabs--line .van-tabs__wrap {
  border-bottom: 1px solid #dbdbdb;
  .van-tab {
    font-size: 18px;
  }
}
.foot{
  height: 18vh;
  width: 15vw;
  color:#fff;
  position: fixed;
  bottom:2vh;
  right: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  img{
    width: 100px;
  }
}
</style>