import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token:'',
    username:'',
    orderId:'',
    parkId:'',
    cardInfo:{},
    detailInfo:{},
    roomToken:'',
    roomUname:'',
    roomIndex:{},
    roomTabBar:{},
    roomData:[],
    gcData:[],
    gcId:'',
    gcUser:'',
    todoList:[],
    AlltodoList:[],
    currentRmNo:'',
    currentComp:''
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
