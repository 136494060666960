/*
    公共的接口配置资源
*/

const base = {
    host:'https://parking.szccbt.com',//基本地址
    // host1:'http://139.186.176.85:8104',//
    login:'/api/bangdao/merchant/v1/login',//登录
    SearchUrl:'/api/bangdao/merchant/v1/enterRecord/pageList',//查询
    detailUrl:'/api/bangdao/merchant/v1/enterRecord/detail',//详情
    cardUrl:'/api/bangdao/merchant/v1/coupon/myCouponList',//查券
    sendUrl:'/api/bangdao/merchant/v1/coupon/sendCoupon',//发券

}

// 单个导出
// export const host='https://parking.szccbt.com'

export default base